<template>
  <div class="main-box" style="padding: 10px" v-if="info">
    <div class="">
      <table width="90%" class="table-box">
        <tr>
          <td colspan="10" class="h2 tc">夕会记录表（{{info.start_date}}）</td>
        </tr>
        <tr>
          <td width="15%">会议时间</td>
          <td class="bg1"><input type="text" placeholder="请输入会议时间" v-model="info.meeting_date" class="input" /> </td>
          <td width="9%">会议日期</td>
          <td class="bg1"><input type="text" placeholder="请输入会议日期" v-model="info.meeting_time" class="input" /> </td>
          <td width="9%">记录人</td>
          <td class="bg1"><input type="text" placeholder="请输入记录人" v-model="info.note_taker" class="input" /> </td>
          <td width="9%">会议主题</td>
          <td class="bg1"><input type="text" placeholder="请输入会议主题" v-model="info.theme" class="input" /> </td>
          <td width="9%">参加人员</td>
          <td class="bg1"><input type="text" placeholder="请输入参加人员" v-model="info.people" class="input" readonly /> </td>
        </tr>
        <tr>
          <td colspan="11" class="h2 tc">会议内容</td>
        </tr>
        <tr> </tr>
        <tr>
          <td colspan="11" class="tc bg4">客户情况（销售自主填写）</td>
        </tr>
      </table>
      <table width="90%" class="table-box" style="margin-top: -1px;">
        <tr>
          <td width="260"></td>
          <td colspan="2" align="center" v-for="(item,index) in info.staff_list" :key="index">{{ item.nickname }}</td>
        </tr>
        <tr v-for="(item,index) in info.daily_log" :key="'s_' + index">
          <td><input type="text" v-model="item.title" class="input" /></td>
          <td colspan="2" v-for="(child,idx) in item.num" :key="'t_' + idx"><input type="text" v-model="item.num[idx]" class="input tc" /></td>
        </tr>
        <tr>
          <td :colspan="colspan" class="tc">用户案例分析</td>
        </tr>
        <tr>
          <td :colspan="colspan" rowspan="3" class="bg2">
            <el-input type="textarea" rows="4" class="text" v-model="info.case_analysis" :autosize="{ minRows: 6 }"></el-input>
          </td>
        </tr>
        <tr> </tr>
        <tr> </tr>
        <tr>
          <td :colspan="colspan" class="tc">门店当月核心指标</td>
        </tr>
        <tr v-for="(item,index) in info.store_month_metrics" :key="'s2_' + index">
          <td><input type="text" v-model="item.title" class="input" /></td>
          <td colspan="2" v-for="(child,idx) in item.num" :key="'t2_' + idx"><input type="text" v-model="item.num[idx]" class="input tc" /></td>
        </tr>
        <tr>
          <td :colspan="colspan" class="tc">客户特殊情况交接</td>
        </tr>
        <tr>
          <td :colspan="colspan" rowspan="8" class="bg2">
            <el-input type="textarea" rows="4" class="text" v-model="info.question_log" :autosize="{ minRows: 6 }"></el-input>
          </td>
        </tr>
      </table>
    </div>
    <div class="operate-box">
<!--      <div>-->
<!--        <el-switch-->
<!--            @change="changeStatus"-->
<!--            v-model="saveStatus"-->
<!--            active-text="开启自动保存">-->
<!--        </el-switch>-->
<!--      </div>-->
      <div class="mg-tp-20">
        <el-button type="success" icon="el-icon-check" circle @click="saveWeek(true)">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      weekData: {},
      saveStatus: false,
      info: false,
      id: 0,
      source_domain: this.config.SOURCE_DOMAIN,
      click: false,
      colspan: 0
    };
  },
  created() {
    // if (this.$route.query.id) {
    //   this.id = this.$route.query.id
    //   this.getInfo()
    // }
  },
  props: {
    dataSource: Object
  },
  watch: {
    dataSource: {
      handler: function(newVal, oldV) {
        this.info = newVal
        if(this.info && this.info.staff_list) {
          this.colspan = this.info.staff_list.length*2+1
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
  },
  beforeRouteLeave(to, from, next) {
    if(this.click) {
      clearInterval(this.click)
      this.click = false
    }
    next()
  },
  computed: {},
  methods: {
    changeStatus(e) {
      if(e) {
        this.click = setInterval(res => {
          this.saveWeek()
        },5000)
      } else {
        clearInterval(this.click)
        this.click = false
      }
    },
    saveWeek(type) {
      var params = JSON.parse(JSON.stringify(this.info))
      this.$api.operate.weekFinish(params, res => {
        if(res.errcode==0) {
          this.success(res.errmsg)
        } else {
          this.fail(res.errmsg)
        }
      })
    },
  }
};
</script>
<style scoped>
.flex {
  display: flex;
  align-items: flex-start;
}
</style>
