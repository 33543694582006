<template>
  <div class="main-box" style="padding: 10px" v-if="info">
    <div class="flex">
      <table width="950" class="table-box">
        <tr>
          <td colspan="10" class="h2 tc">门店周会会议记录表（{{info.start_date}} ~ {{info.end_date}}）</td>
        </tr>
        <tr>
          <td width="9%">会议时间</td>
          <td class="bg1"><input type="text" placeholder="请输入会议时间" v-model="info.meeting_date" class="input" /> </td>
          <td width="9%">会议日期</td>
          <td class="bg1"><input type="text" placeholder="请输入会议日期" v-model="info.meeting_time" class="input" /> </td>
          <td width="9%">记录人</td>
          <td class="bg1"><input type="text" placeholder="请输入记录人" v-model="info.note_taker" class="input" /> </td>
          <td width="9%">会议主题</td>
          <td class="bg1"><input type="text" placeholder="请输入会议主题" v-model="info.theme" class="input" /> </td>
          <td width="9%">参加人员</td>
          <td class="bg1"><input type="text" placeholder="请输入参加人员" v-model="info.people" class="input" readonly /> </td>
        </tr>
        <tr>
          <td colspan="10" class="h2 tc">会议内容</td>
        </tr>
        <tr>
          <td colspan="10" class="tc">财务情况</td>
        </tr>
        <tr>
          <td colspan="10" class="tc">（提前准备的材料：财务报销管理表，门店财务总表，各人员工资细项表）</td>
        </tr>
        <tr>
          <td colspan="10" class="tc">（涉及内容：成本管控，人员成本，业务分析）</td>
        </tr>
        <tr>
          <td colspan="10" class="tc h5 bg2">
            <el-input type="textarea" rows="4" class="text" v-model="info.finance_situation" :autosize="{ minRows: 6 }"></el-input>
          </td>
        </tr>
        <tr>
          <td colspan="10" class="tc">业务情况</td>
        </tr>
        <tr>
          <td colspan="10" class="tc">（提前准备的材料：门店业务考核表，渠道管理追踪表及其目标，渠道推进日记）</td>
        </tr>
        <tr>
          <td colspan="10" class="tc">（涉及内容：渠道情况，市场动态，价格情况，销售人员情况）</td>
        </tr>
        <tr>
          <td colspan="10" class="tc h5 bg2">
            <el-input type="textarea" rows="4" class="text" v-model="info.business_situation" :autosize="{ minRows: 6 }"></el-input>
          </td>
        </tr>
        <tr>
          <td colspan="10" class="tc">门店情况</td>
        </tr>
        <tr>
          <td colspan="10" class="tc">
            <div style="display: flex; align-items: center; margin: 0 auto; justify-content: center">（提前准备的材料：<el-link type="warning" @click="showFour">上周值班表<i class="el-icon-share"></i></el-link>）</div>
          </td>
        </tr>
        <tr>
          <td colspan="10" class="tc">（涉及内容：门店环境标准执行，技术团队和标准情况）</td>
        </tr>
        <tr>
          <td colspan="10" class="tc h5 bg2">
            <el-input type="textarea" rows="4" class="text" v-model="info.store_situation" :autosize="{ minRows: 6 }"></el-input>
          </td>
        </tr>
        <tr>
          <td colspan="10" class="tc">产品及其供应链情况</td>
        </tr>
        <tr>
          <td colspan="10" class="tc">（提前准备的材料：售后记录表，门店财务总表，订货数据）</td>
        </tr>
        <tr>
          <td colspan="10" class="tc">（涉及内容：新品推广，套餐售卖，产品售后及其产品销售情况）</td>
        </tr>
        <tr>
          <td colspan="10" class="tc h5 bg2">
            <el-input type="textarea" rows="4" class="text" v-model="info.product_situation" :autosize="{ minRows: 6 }"></el-input>
          </td>
        </tr>
        <tr>
          <td colspan="10" class="tc">重要且紧急情况</td>
        </tr>
        <tr>
          <td colspan="10" class="tc h5 bg2">
            <el-input type="textarea" rows="4" class="text" v-model="info.important_situation" :autosize="{ minRows: 6 }"></el-input>
          </td>
        </tr>
      </table>
      <table width="500" class="table-box" style="margin-left: 20px">
        <tr>
          <td class="bg3" width="25%" align="center">月度目标</td>
          <td class="bg3" colspan="3" align="center">完成情况</td>
        </tr>
        <tr v-for="(item,index) in info.month_plan" :key="index">
          <td align="center"><input type="text" v-model="item.title" class="input tc" /></td>
          <td align="center" colspan="3"><input type="text" v-model="item.content" class="input tc" /></td>
        </tr>
        <tr>
          <td class="bg2" colspan="2" align="center">上周计划落地情况</td>
          <td class="bg2" colspan="2" align="center">本周目标及其计划</td>
        </tr>
        <tr>
          <td colspan="2" rowspan="4" align="center" class="bg0">
            <el-input type="textarea" rows="4" class="text" v-model="info.lastweek_target" :autosize="{ minRows: 12 }"></el-input>
          </td>
          <td colspan="2" align="center">目标设定：</td>
        </tr>
        <tr>
          <td colspan="2" align="center" class="bg0">
            <el-input type="textarea" rows="4" class="text" v-model="info.week_target" :autosize="{ minRows: 4 }"></el-input>
          </td>
        </tr>
        <tr>
          <td colspan="2" align="center">本周计划</td>
        </tr>
        <tr>
          <td colspan="2" align="center" class="bg0">
            <el-input type="textarea" rows="4" class="text" v-model="info.week_plan" :autosize="{ minRows: 4 }"></el-input>
          </td>
        </tr>
      </table>
    </div>
    <div class="operate-box">
<!--      <div>-->
<!--        <el-switch-->
<!--            @change="changeStatus"-->
<!--            v-model="saveStatus"-->
<!--            active-text="开启自动保存">-->
<!--        </el-switch>-->
<!--      </div>-->
      <div class="mg-tp-20">
        <el-button type="success" icon="el-icon-check" circle @click="saveWeek(true)">保存</el-button>
      </div>
    </div>
    <el-dialog
        :visible.sync="dialogLogVisible"
        width="90%">
      <week-template :data="weekData" :isEdit="false"></week-template>
    </el-dialog>
  </div>
</template>
<script>

import WeekTemplate from "@/components/storeFour/WeekTemplate";
export default {
  components: {
    WeekTemplate
  },
  data() {
    return {
      weekData: {},
      saveStatus: false,
      dialogLogVisible: false,
      info: false,
      id: 0,
      source_domain: this.config.SOURCE_DOMAIN,
      click: false
    };
  },
  created() {
    // if (this.$route.query.id) {
    //   this.id = this.$route.query.id
    //   this.getInfo()
    // }
  },
  props: {
    dataId: Number
  },
  watch: {
    dataId: {
      handler: function(newVal, oldV) {
        this.id = newVal
        this.getInfo()
        console.log(newVal)
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
  },
  beforeRouteLeave(to, from, next) {
    if(this.click) {
      clearInterval(this.click)
      this.click = false
    }
    next()
  },
  computed: {},
  methods: {
    getInfo() {
      this.$api.operate.weekDetail({id:this.id},res => {
        if(res.errcode==0) {
          this.info = res.data
          if(!this.info.month_plan) {
            this.info.month_plan = []
            this.info.month_plan.push({title:'分红7W',content:''})
            this.info.month_plan.push({title:'制度落实',content:''})
            this.info.month_plan.push({title:'代施工完成体系',content:''})
            this.info.month_plan.push({title:'',content:''})
            this.info.month_plan.push({title:'',content:''})
          }
        }
      })
    },
    changeStatus(e) {
      if(e) {
        this.click = setInterval(res => {
          this.saveWeek()
        },5000)
      } else {
        clearInterval(this.click)
        this.click = false
      }
    },
    saveWeek(type) {
      if(type) {
        clearInterval(this.click)
        this.click = false
        this.saveStatus = false
      }
      var params = JSON.parse(JSON.stringify(this.info))
      params.is_finish = this.click ? 0 : 1
      this.$api.operate.weekFinish(params, res => {
        if(res.errcode==0) {
          if(params.is_finish) {
            this.success(res.errmsg)
          }
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    showFour() {
      if(!this.info.weekData) {
        this.fail('值班表尚未创建')
        return false
      }
      setTimeout(res => {
        this.weekData = this.info.weekData
      },100)
      this.dialogLogVisible = true
    }
  }
};
</script>
<style scoped>
.flex {
  display: flex;
  align-items: flex-start;
}
</style>
