<template>
  <div class="main-box" style="padding: 10px 10px 40px 10px; width: 100%;" v-if="info">
    <div class="flex">
      <div style="display: flex; align-items: center; position: fixed; width: 100%; text-align: center; justify-content: center; margin-left: -200px; margin-top: -50px; z-index: 10001;">
        <el-button type="primary" icon="el-icon-d-arrow-left" circle style="margin-right: 100px;" @click="goNext(1)"></el-button>
        <el-button type="primary" icon="el-icon-d-arrow-right" circle @click="goNext(2)"></el-button>
      </div>
      <morning-meeting-template :isEdit="true" :dataSource="content" :dataId="id" :callFunc="changeContent" v-if="info.c_type==1"></morning-meeting-template>
      <night-meeting-template :isEdit="true" :dataSource="info" :dataId="id" :callFunc="changeContent" v-if="info.c_type==2"></night-meeting-template>
      <week-meeting-template :isEdit="true" :dataSource="content" :dataId="id" :callFunc="changeContent" v-if="info.c_type==3"></week-meeting-template>
    </div>
  </div>
</template>
<script>

import MorningMeetingTemplate from "@/components/week/MorningMeetingTemplate";
import NightMeetingTemplate from "@/components/week/NightMeetingTemplate";
import WeekMeetingTemplate from "@/components/week/WeekMeetingTemplate";
export default {
  components: {
    MorningMeetingTemplate,
    NightMeetingTemplate,
    WeekMeetingTemplate,
  },
  data() {
    return {
      info: {},
      id: 0,
      source_domain: this.config.SOURCE_DOMAIN,
      content: {}
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = parseInt(this.$route.query.id)
      this.getInfo()
    }
  },
  mounted() {
  },
  watch: {
    '$route' (to, from) {
      this.id = to.query.id
      this.getInfo()
    }
  },
  computed: {},
  methods: {
    getInfo() {
      this.showLoading()
      this.$api.operate.weekDetail({id:this.id},res => {
        this.hideLoading()
        if(res.errcode==0) {
          this.info = res.data
          setTimeout(res => {
            this.content = this.info
          },100)
        }
      })
    },
    goNext(type) {
      if(type==1) {
        if(this.info.before_id==0) {
          this.fail('没有更早记录了')
        } else {
          this.$router.push({name: 'operate_week_show', query: {id: this.info.before_id}})
        }
      } else if(type==2) {
        if(this.info.next_id==0) {
          this.fail('没有更新记录了')
        } else {
          this.$router.push({name: 'operate_week_show', query: {id: this.info.next_id}})
        }
      }
    },
    changeContent(data) {
      var params = {
        id: this.id,
        data: data
      }
      this.$api.operate.storefourFinish(params, res => {
        if(res.errcode==0) {
          // this.success(res.errmsg)
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
</style>
