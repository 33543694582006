<template>
  <div class="box" style="padding: 0; margin: 0;">
    <table width="100%" class="table-xls tc bg0" style="min-width: 1100px;">
      <tr>
        <td colspan="10" class="h2 tc black">店长每周工作流程（{{info.start_date}} ~ {{info.end_date}}）</td>
      </tr>
      <tr>
        <td width="4%">序号</td>
        <td width="7%">时间</td>
        <td width="4%">段落</td>
        <td width="9%">事项</td>
        <td width="7%">区域</td>
        <td width="4%">编号</td>
        <td width="33%">动作（细则）</td>
        <td width="10%">工具</td>
        <td width="15%">备注</td>
        <td width="7%">状态</td>
      </tr>
      <tr>
        <td rowspan="2">1</td>
        <td rowspan="2">蓝电运营教练到店</td>
        <td rowspan="2">1</td>
        <td rowspan="2">蓝电运营教练行动计划整改</td>
        <td rowspan="2">开会场地</td>
        <td>1.1</td>
        <td class="left">1.根据教练给出的行动计划表分析问题</td>
        <td rowspan="2">行动计划表详见-附件10</td>
        <td rowspan="2"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k1.remark" :disabled="!isEdit" @change="inputChange(content.k1)"></textarea></td>
        <td rowspan="2">
          <div v-if="content.k1.status==1">已完成<br>{{content.k1.time}}</div>
          <el-popconfirm v-else-if="isEdit"
              title="确认完成吗？"
              @confirm="sureDay(content.k1)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>1.2</td>
        <td class="left">2.就产生的问题做出改进计划,明确责任人，完成时间，签字承诺,周会同步全体人员</td>
      </tr>
      <tr>
        <td rowspan="5">2</td>
        <td rowspan="5">周一</td>
        <td rowspan="5">2</td>
        <td rowspan="5">周卫生大扫除</td>
        <td rowspan="5">店内各工作区域</td>
        <td>2.1</td>
        <td class="left">1.根据各区域卫生值班表进行</td>
        <td rowspan="4">5S周标准</td>
        <td rowspan="5"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k2.remark" :disabled="!isEdit" @change="inputChange(content.k1)"></textarea></td>
        <td rowspan="5">
          <div v-if="content.k2.status==1">已完成<br>{{content.k2.time}}</div>
          <el-popconfirm v-else-if="isEdit"
               title="确认完成吗？"
               @confirm="sureDay(content.k2)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>2.2</td>
        <td class="left">2.按照门店5S周标准，深度清洁</td>
      </tr>
      <tr>
        <td>2.3</td>
        <td class="left">3.带领各小组负责人进行检查</td>
      </tr>
      <tr>
        <td>2.4</td>
        <td class="left">4.检查安全隐患，整理库存，盘点是否补货</td>
      </tr>
      <tr>
        <td>2.5</td>
        <td class="left">5.清洁整理门店用车，安排充电，充电不可低于20%</td>
        <td>门店车辆管理守则</td>
      </tr>
      <tr>
        <td rowspan="6">3</td>
        <td rowspan="6">周二</td>
        <td rowspan="3">3</td>
        <td rowspan="3">店内管理层会议</td>
        <td rowspan="3">开会场地</td>
        <td>3.1</td>
        <td class="left">1.上午10:00-11:00，60分钟，替代当日早会</td>
        <td rowspan="3">会议纪要详见-附件11（周会记录表）</td>
        <td rowspan="3"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k31.remark" :disabled="!isEdit" @change="inputChange(content.k1)"></textarea></td>
        <td rowspan="3">
          <div v-if="content.k31.status==1">已完成<br>{{content.k31.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k31)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>3.2</td>
        <td class="left">2.组织会议，分析周报，进行上周总结和本周计划，传达门店信息</td>
      </tr>
      <tr>
        <td>3.3</td>
        <td class="left">3.听取各小组组长：汇报工作总结和计划（业绩、管理、服务分析，改进措施，所需支持，下周目标，上周改进情况</td>
      </tr>
      <tr>
        <td rowspan="3">4</td>
        <td rowspan="3">店内销售人员或者技术人员会议</td>
        <td rowspan="3">开会场所</td>
        <td>4.1</td>
        <td class="left">1.组织员工交流经验</td>
        <td rowspan="3">周会记录表</td>
        <td rowspan="3"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k32.remark" :disabled="!isEdit" @change="inputChange(content.k1)"></textarea></td>
        <td rowspan="3">
          <div v-if="content.k32.status==1">已完成<br>{{content.k32.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k32)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>4.2</td>
        <td class="left">2.组织员工培训学习</td>
      </tr>
      <tr>
        <td>4.3</td>
        <td class="left">3.分析上周工作进度以及回馈问题</td>
      </tr>
      <tr>
        <td rowspan="3">4</td>
        <td rowspan="3">周四</td>
        <td rowspan="3">5</td>
        <td rowspan="3">店长周会<br />区域连锁店</td>
        <td rowspan="3">开会场地</td>
        <td>5.1</td>
        <td class="left">1.汇报店长周工作计划</td>
        <td rowspan="3"></td>
        <td rowspan="3"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k4.remark" :disabled="!isEdit" @change="inputChange(content.k1)"></textarea></td>
        <td rowspan="3">
          <div v-if="content.k4.status==1">已完成<br>{{content.k4.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k4)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>5.2</td>
        <td class="left">2.听取运营对区域工作安排</td>
      </tr>
      <tr>
        <td>5.3</td>
        <td class="left">3.店长之间的经验分享</td>
      </tr>
      <tr>
        <td rowspan="3">5</td>
        <td rowspan="3">周五</td>
        <td rowspan="3">6</td>
        <td rowspan="3">检查门店销售力提高推进进度</td>
        <td rowspan="3">前台</td>
        <td>6.1</td>
        <td class="left">1.检查产品专家销售对话，6内容，9元素，6步走</td>
        <td rowspan="3">渠道管理表</td>
        <td rowspan="3"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k5.remark" :disabled="!isEdit" @change="inputChange(content.k1)"></textarea></td>
        <td rowspan="3">
          <div v-if="content.k5.status==1">已完成<br>{{content.k5.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k5)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>6.2</td>
        <td class="left">2.检查渠道推进的情况和行动</td>
      </tr>
      <tr>
        <td>6.3</td>
        <td class="left">3.检查运营教练行动计划的落地情况</td>
      </tr>
      <tr>
        <td rowspan="8">6</td>
        <td rowspan="8">周日</td>
        <td rowspan="3">7</td>
        <td rowspan="3">工作排班</td>
        <td rowspan="3">开会场地</td>
        <td>7.1</td>
        <td class="left">1.在周会前完成人员排班并下周一公布</td>
        <td rowspan="3">排班表详见-附件12</td>
        <td rowspan="3"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k61.remark" :disabled="!isEdit" @change="inputChange(content.k1)"></textarea></td>
        <td rowspan="3">
          <div v-if="content.k61.status==1">已完成<br>{{content.k61.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k61)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>7.2</td>
        <td class="left">2.人员排班考虑门店运营状况，天气，节假日等情况合理排班</td>
      </tr>
      <tr>
        <td>7.3</td>
        <td class="left">3.对请假，换班的员工，安排好替班人员</td>
      </tr>
      <tr>
        <td rowspan="4">8</td>
        <td rowspan="4">周工作计划</td>
        <td rowspan="4">开会场地</td>
        <td>8.1</td>
        <td class="left">1.周经营业绩目标达成分析，业绩提升的计划</td>
        <td rowspan="4">周计划表详见-附件13</td>
        <td rowspan="4"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k62.remark" :disabled="!isEdit" @change="inputChange(content.k1)"></textarea></td>
        <td rowspan="4">
          <div v-if="content.k62.status==1">已完成<br>{{content.k62.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k62)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>8.2</td>
        <td class="left">2.总结车主访谈和客诉处理</td>
      </tr>
      <tr>
        <td>8.3</td>
        <td class="left">3.门店每周一培训需求申请和安排</td>
      </tr>
      <tr>
        <td>8.4</td>
        <td class="left">4.遇到问题所需支持</td>
      </tr>
      <tr>
        <td>9</td>
        <td>制作运营手册记录表</td>
        <td>电脑</td>
        <td>9.1</td>
        <td class="left">制作运营手册</td>
        <td>打印机</td>
        <td><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k63.remark" :disabled="!isEdit" @change="inputChange(content.k1)"></textarea></td>
        <td>
          <div v-if="content.k63.status==1">已完成<br>{{content.k63.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k63)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td rowspan="2">7</td>
        <td rowspan="2">不定时事项</td>
        <td rowspan="2">9</td>
        <td rowspan="2">客户离店次日<br />店长回访</td>
        <td rowspan="2">前台</td>
        <td>9.1</td>
        <td class="left">1.（回访查询路径：F6PC端-工单-单据管理-更多筛选-进厂日期、结算状态）每月完成5名车主回访</td>
        <td rowspan="2">回访表详见-附件14</td>
        <td rowspan="2"><textarea class="text" :placeholder="isEdit ? '点击输入备注' : ''" v-model="content.k7.remark" :disabled="!isEdit" @change="inputChange(content.k1)"></textarea></td>
        <td rowspan="2">
          <div v-if="content.k7.status==1">已完成<br>{{content.k7.time}}</div>
          <el-popconfirm v-else-if="isEdit"
                         title="确认完成吗？"
                         @confirm="sureDay(content.k7)"
          >
            <el-button slot="reference" type="primary" size="mini">完成</el-button>
          </el-popconfirm>
        </td>
      </tr>
      <tr>
        <td>9.2</td>
        <td class="left">3.回访近一周的返工车辆车主；询问产品售后是否解决。</td>
      </tr>
    </table>
  </div>
</template>
<script>

import util from "@/utils/util";
export default {
  data() {
    return {
      info: false,
      content: {
        k1: {title:'蓝电运营教练到店',remark:'',status:'',time:''},
        k2: {title:'周一',remark:'',status:'',time:''},
        k31: {title:'周二店内管理层会议',remark:'',status:'',time:''},
        k32: {title:'周二店内销售人员或者技术人员会议',remark:'',status:'',time:''},
        k4: {title:'周四',remark:'',status:'',time:''},
        k5: {title:'周五',remark:'',status:'',time:''},
        k61: {title:'周日工作排班',remark:'',status:'',time:''},
        k62: {title:'周日周工作计划',remark:'',status:'',time:''},
        k63: {title:'周日制作运营手册记录表',remark:'',status:'',time:''},
        k7: {title:'不定时事项',remark:'',status:'',time:''}
      }
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  props: {
    isEdit: Boolean,
    data: Object,
    callFunc: Function
  },
  watch: {
    data(newVal) {
      if(newVal) {
        this.info = newVal
        if(newVal.content) {
          this.content = newVal.content
        }
      }
    }
  },
  methods: {
    inputChange(item) {
      this.rsyncData()
    },
    sureDay(item) {
      item.status = 1
      item.time = util.formatTime(new Date())
      this.rsyncData()
    },
    rsyncData() {
      if(this.callFunc) {
        this.callFunc(this.content)
      }
    }
  }
};
</script>
<style scoped>
</style>
